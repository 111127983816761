<template>
  <div class="px-4 pb-6">
    <v-icon class="mx-auto d-block mb-3" color="warning" size="54"
      >$warning</v-icon
    >

    <div class="font-medium-16 gray13--text text-center mb-2">
      {{
        $t(
          "panel.transactionsPage.depositTransactions.refundModal.notSufficientAmount"
        )
      }}
    </div>

    <div class="gray9--text text-center mb-8">
      {{
        $t(
          "panel.transactionsPage.depositTransactions.refundModal.notSufficientAmountDesc"
        )
      }}
    </div>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{
          $t("panel.transactionsPage.depositTransactions.refundModal.amount")
        }}
      </div>

      <span class="gray12--text"
        >{{ trxDetails.amountString }} {{ trxDetails.iso }}</span
      >
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{ $t("panel.transactionsPage.depositTransactions.refundModal.fee") }}
      </div>

      <span class="error--text"
        >{{ trxDetails.feeString }} {{ trxDetails.iso }}</span
      >
    </div>
    <v-divider></v-divider>

    <div
      class="d-sm-flex align-center justify-sm-space-between text-center my-4"
    >
      <div class="font-regular-12 gray9--text">
        {{
          $t("panel.transactionsPage.depositTransactions.refundModal.network")
        }}
      </div>

      <span class="gray12--text">{{ trxDetails.network }}</span>
    </div>

    <v-btn
      block
      class="radius-2"
      color="primary"
      depressed
      height="47"
      @click="closeModal"
    >
      <span class="font-semiBold-14">
        {{
          $t(
            "panel.transactionsPage.depositTransactions.refundModal.understand"
          )
        }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    trxDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
