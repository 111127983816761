<template>
  <div class="px-4 pb-6">
    <div class="gray10--text font-medium-16 mb-4 d-flex justify-center">
      {{ $t("panel.transactionsPage.depositTransactions.refundModal.refund") }}
    </div>

    <v-card class="px-3 py-2 mb-6 d-flex align-center" color="infoBg">
      <v-icon size="20">$info</v-icon>

      <ul>
        <li
          v-for="(item, index) in $t(
            'panel.transactionsPage.depositTransactions.refundModal.warnings'
          )"
          :key="index"
          class="font-regular-12 info--text"
        >
          {{ item }}
        </li>
      </ul>
    </v-card>

    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t("panel.transactionsPage.depositTransactions.refundModal.crypto")
          }}
        </div>

        <span class="gray13--text">{{ trxDetails.cryptoName }}</span>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t("panel.transactionsPage.depositTransactions.refundModal.amount")
          }}
        </div>

        <span class="gray13--text"
          >{{ trxDetails.amountString }} {{ trxDetails.iso }}</span
        >
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{ $t("panel.transactionsPage.depositTransactions.refundModal.fee") }}
        </div>

        <span class="gray13--text"
          >{{ trxDetails.feeString }} {{ trxDetails.iso }}</span
        >
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t("panel.transactionsPage.depositTransactions.refundModal.network")
          }}
        </div>

        <span class="gray13--text">{{ trxDetails.network }}</span>
      </div>

      <v-text-field
        v-model="refundModel.toAddress"
        :label="
          $t(
            'panel.transactionsPage.depositTransactions.refundModal.walletAddress'
          )
        "
        :rules="walletAddressRules"
        class="theme-custom-input fields-custom-width mb-11 mt-6"
        filled
        hide-details="auto"
        required
      >
      </v-text-field>

      <v-card
        class="mb-5 px-4 radius-3 d-flex align-center"
        color="gray1"
        height="64"
      >
        <v-row align="center" justify="space-between" no-gutters>
          <div class="font-regular-12 gray7--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.refundModal.receivedAmount"
              )
            }}
          </div>
          <div class="text-right">
            <span class="font-regular-14 gray13--text me-1">
              {{ trxDetails.amount - trxDetails.fee }}
            </span>
            <span class="font-regular-14 gray13--text">{{
              trxDetails.iso
            }}</span>
            <div class="asset-equivalent">
              <span class="font-regular-12 gray7--text">
                ≈
                <span class="font-regular-12 gray7--text"
                  >${{ parseFloat(trxDetails.exactDollar) }}</span
                >
              </span>
            </div>
          </div>
        </v-row>
      </v-card>

      <v-checkbox
        v-model="refundModel.accepted"
        :ripple="false"
        :rules="checkedAddressRules"
        class="mb-6 mt-0"
        dense
        hide-details="auto"
        off-icon="$emptyCheckbox"
        on-icon="$activeCheckbox"
        required
      >
        <template v-slot:label>
          <div class="gray6--text font-regular-14">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.refundModal.confirmation"
              )
            }}
          </div>
        </template>
      </v-checkbox>

      <v-btn
        :disabled="!valid"
        :loading="loading"
        block
        class="radius-2"
        color="primary"
        depressed
        height="47"
        type="submit"
      >
        <span class="font-regular-16">
          {{
            $t("panel.transactionsPage.depositTransactions.refundModal.refund")
          }}
        </span>
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { AccountService } from "@/services";

export default {
  name: "index",
  props: {
    trxDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    walletAddressRules() {
      let rules = [];

      if (!this.refundModel.toAddress) {
        const required = [
          (v) =>
            !!v ||
            this.$t(
              "panel.transactionsPage.depositTransactions.refundModal.walletAddressNotEmpty"
            ),
        ];

        rules = required;
      } else if (this.walletRegex) {
        const match = [
          (v) =>
            new RegExp(this.walletRegex).test(v) ||
            this.$t(
              "panel.transactionsPage.depositTransactions.refundModal.walletAddressNotValid"
            ),
        ];

        rules = match;
      }

      return rules;
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      walletRegex: "",
      refundModel: {
        transactionId: "",
        toAddress: "",
        network: "",
        accepted: false,
      },
      checkedAddressRules: [
        (v) =>
          !!v ||
          this.$t(
            "panel.transactionsPage.depositTransactions.refundModal.checkedAddressRequired"
          ),
      ],
    };
  },
  mounted() {
    this.getWalletRegex();
  },
  methods: {
    getWalletRegex() {
      AccountService.getWalletRegex(this.trxDetails.network).then((res) => {
        this.walletRegex = res.data.data;
      });
    },
    submit() {
      this.loading = true;
      this.refundModel.transactionId = this.trxDetails.trxId;
      this.refundModel.network = this.trxDetails.network;
      AccountService.refundTransaction(this.refundModel)
        .then((res) => {
          this.$toast.success(res.data.message, {
            icon: this.$vuetify.icons.values.checkMarkDone,
          });
          this.$emit("updateRecord", this.trxDetails.trxId);
          this.$emit("close");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
